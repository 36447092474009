<template>
  <b-modal
    id="modal-policy"
    scrollable
    no-close-on-esc
    no-close-on-backdrop
    @show="addScrollListener"
  >
    <template v-slot:modal-title>
      <span>ข้อกำหนดและเงื่อนไข<br />การใช้บริการ SKL Connect</span>
    </template>
    <template v-slot:default>
      <template v-if="content == ''">
        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >ผู้ขอใช้บริการตกลงใช้บริการ SKL คอนเน็กต์ (SKL Connect) ของบริษัท
            สยามคูโบต้า ลีสซิ่ง จำกัด ซึ่งต่อไปนี้จะเรียกว่า
            &ldquo;บริษัทฯ&rdquo; ตามที่ได้รับอนุมัติจากบริษัทฯ
            และตามรายละเอียดการให้บริการที่บริษัทฯ กำหนด
            โดยผู้ขอใช้บริการตกลงผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการ
            และรับทราบนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ ดังต่อไปนี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-right:8.5pt;text-align:left;text-indent:8.95pt;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >1. คำนิยาม</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.1 &ldquo;ข้อมูลส่วนบุคคล&rdquo; หมายความถึง ข้อมูลส่วนบุคคล
            ข้อมูลทางการเงิน ข้อมูลการทำธุรกรรม และ/หรือ ข้อมูลใดๆ
            ของผู้ขอใช้บริการที่ไว้แก่บริษัทฯ หรือที่บริษัทฯได้รับ
            หรือเข้าถึงได้จากแหล่งอื่น</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.2 &ldquo;เครื่องมือเพื่อการทำรายการ&rdquo; หมายความถึง
            โทรศัพท์มือถือ และ/หรือ อุปกรณ์อิเล็กทรอนิกส์ใดๆ
            ของผู้ขอใช้บริการที่มีการดาวน์โหลดและติดตั้งโปรแกรม LINE Application
            สำหรับใช้บัญชี LINE ที่ผู้ขอใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            และ/หรือ ใช้ในการทำรายการการใช้บริการ SKL คอนเน็กต์ (SKL Connect)
          </span>
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.3 &ldquo;โทรศัพท์สำหรับการยืนยันการทำธุรกรรม&rdquo; หมายความถึง
            โทรศัพท์ที่ใช้หมายเลขโทรศัพท์มือถือที่ผู้ขอใช้บริการแจ้งไว้กับบริษัทฯ
            ณ ตอนทำสัญญาเช่าซื้อกับบริษัทฯ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.4 &ldquo;บริษัทฯ&rdquo; หมายความถึง บริษัท สยามคูโบต้า ลีสซิ่ง
            จำกัด</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.5 &ldquo;ผู้ขอใช้บริการ&rdquo; หมายความถึง
            บุคคลซึ่งได้รับอนุมัติจากบริษัทฯ ให้เป็นผู้ใช้บริการ SKL คอนเน็กต์
            (SKL Connect) ภายใต้ข้อกำหนดและเงื่อนไขการใช้บริการนี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.6 &ldquo;บัญชีข้อมูลสินเชื่อ&rdquo; หมายความถึง สินเชื่อเช่าซื้อ
            และ/หรือสินเชื่ออื่นใดที่ผู้ขอใช้บริการที่มีอยู่กับบริษัทฯ
            และบริษัทฯ ได้กำหนดให้สินเชื่อประเภทดังกล่าวสามารถใช้บริการนี้
            ภายใต้ข้อกำหนดและเงื่อนไขการใช้บริการนี้ด้วย</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.7 &ldquo;บัญชี LINE&rdquo; หมายความถึง
            บัญชีหรือข้อมูลการเป็นผู้ใช้บริการระบบปฏิบัติการ LINE Application
            ของผู้ขอใช้บริการ
            ซึ่งผู้ขอใช้บริการได้สร้างหรือจัดทำขึ้นโดยลงทะเบียนขอใช้บริการบนระบบปฏิบัติการ
            LINE Application ตามฐานข้อมูลผู้ให้บริการ LINE Application</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.8 &ldquo;บัญชี SKL Connect&rdquo; หรือ &ldquo;บัญชี SKL
            Connect&rdquo; หมายความถึง
            บัญชีหรือข้อมูลของบุคคลซึ่งได้รับอนุมัติจากบริษัทฯ
            ให้เป็นผู้ใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            ภายใต้ข้อกำหนดและเงื่อนไขการใช้บริการนี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.9 &ldquo;ผู้ให้บริการ LINE Application&rdquo; หมายความถึง บริษัท
            ไลน์ คอมพานี (ประเทศไทย) จำกัด และให้หมายความถึงบุคคลที่บริษัท ไลน์
            คอมพานี (ประเทศไทย) จำกัด มอบหมายด้วย</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:22.7pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >1.10 &ldquo;เหตุสุดวิสัย&rdquo; หมายความถึง เหตุใด ๆ
            อันอยู่นอกเหนือการควบคุมของบริษัทฯ
            ซึ่งเป็นอุปสรรคต่อการให้บริการตามข้อกำหนดและเงื่อนไขการใช้บริการนี้
            รวมถึงเป็นเหตุให้เกิดข้อขัดข้อง หรือข้อมูลผิดพลาด เช่น
            ระบบคอมพิวเตอร์ของบริษัทฯ
            หรือระบบการสื่อสารขัดข้องเพราะกระแสไฟฟ้าขัดข้อง หรือปัญหาด้านพลังงาน
            ระบบอินเทอร์เน็ตของผู้ให้บริการ (Internet Service Provider)
            หรือของผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่ (Mobile Operator)
            ระบบติดต่อสื่อสารโทรคมนาคม
            หรือระบบอื่นใดที่เกี่ยวข้องกับการให้บริการ การกระทำของบุคคลภายนอก
            ไวรัสคอมพิวเตอร์ หรือข้อมูลที่อันตรายต่าง ๆ
            ซึ่งเหตุดังกล่าวไม่ได้มีสาเหตุมาจากการที่บริษัทฯ
            ไม่ดูแลรักษาระบบคอมพิวเตอร์อย่างเพียงพอตามมาตราฐาน รวมถึง SKL
            Connect ชำรุดขัดข้อง อยู่ระหว่างการซ่อมแซม
            หรือปิดระบบชั่วคราวเพื่อบำรุงรักษา
            เป็นเหตุให้ผู้ขอใช้บริการไม่สามารถใช้บริการ SKL คอนเน็กต์ (SKL
            Connect)
            ผู้ขอใช้บริการตกลงจะไม่ยกเอาเหตุขัดข้องดังกล่าวมาเป็นข้อเรียกร้องให้บริษัทฯ
            รับผิดชอบแต่ประการใด</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong>
            <u>
              <span style='font-family:"Kanit", sans-serif;'
                >2. คุณสมบัติผู้ขอใช้บริการและการลงทะเบียนใช้บริการ</span
              >
            </u>
          </strong>
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >2.1 ผู้ขอใช้บริการจะต้องเป็นลูกค้าของบริษัทฯ
            ที่มีสถานะสัญญาอยู่ระหว่างการใช้บริการสินเชื่อกับบริษัทฯ
            และมียอดค้างชำระไม่เกินระยะเวลาที่บริษัทฯกำหนด มีบัญชี LINE
            และเป็นเพื่อนกับบัญชี SKL LINE Official
            เพื่อรับข้อมูลข่าวสารหรือกิจกรรมทางการตลาด
            กิจกรรมส่งเสริมการขายของบริษัทฯ ในระบบปฏิบัติการ LINE Application
            ตามฐานข้อมูลของผู้ให้บริการ LINE Application</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >2.2 ผู้ขอใช้บริการต้องลงทะเบียนขอใช้บริการ SKL คอนเน็กต์ (SKL
            Connect) ตามวิธีและเงื่อนไข ดังนี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:35.4pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(1) ขอใช้บริการต้องทำรายการผ่านบัญชี LINE ในระบบปฏิบัติการ LINE
            Application บนเครื่องมือเพื่อทำรายการของผู้ขอใช้บริการ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-right:8.5pt;text-align:left;text-indent:43.95pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(2)
            ผู้ขอใช้บริการต้องยืนยันตัวตนและยอมรับข้อกำหนดและเงื่อนไขการเข้าใช้บริการเพื่อลงทะเบียนขอใช้บริการ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:35.4pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(3)
            ในกรณีที่ผลการตรวจสอบข้อมูลยืนยันตัวตนของผู้ขอใช้บริการถูกต้องตามข้อกำหนดของบริษัทฯ
            ระบบจะแจ้งผลการยืนยันตัวตน
            และอนุญาตให้ผู้ขอใช้บริการเข้าสู่หน้าหลักเพื่อใช้บริการทันที</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:35.4pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >ทั้งนี้ ในกรณีที่ผลการตรวจสอบปรากฏว่าข้อมูลไม่ถูกต้อง
            หรือระบบตรวจสอบข้อมูลเพื่อยืนยันตัวตนผู้ขอใช้บริการไม่ได้ภายในเวลาตามที่กำหนด
            ให้ถือว่า การลงทะเบียนขอใช้บริการไม่สำเร็จ
            และผู้ขอใช้บริการจะได้รับแจ้งให้ทราบทันที</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:35.4pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(4) เมื่อการลงทะเบียนขอใช้บริการสำเร็จตามเงื่อนไขที่บริษัทฯ
            กำหนดแล้วจะมีผลการสร้าง SKL คอนเน็กต์ (SKL Connect)
            ของผู้ขอใช้บริการสำหรับการใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            ตามข้อกำหนดและเงื่อนไขการใช้บริการนี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:35.4pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(5) บัญชี LINE 1 บัญชี สามารถสร้างบัญชี SKL คอนเน็กต์ (SKL Connect)
            ได้ 1 บัญชีเท่านั้น</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >2.3 ผู้ขอใช้บริการรับรองว่าข้อมูลใด ๆ ที่ผู้ขอใช้บริการได้ให้
            และ/หรือ จะให้แก่บริษัทฯ ในภายหน้าถูกต้อง ครบถ้วน
            และเป็นจริงทุกประการ
            และขอรับรองว่าผู้ขอใช้บริการมีสิทธิและมีความสามารถตามกฎหมายในการขอใช้บริการและการทำรายการใด
            ๆ ที่เกี่ยวข้องกับบริการนี้
            รวมถึงเป็นเจ้าของและผู้ครอบครองเครื่องมือเพื่อการทำรายการ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >3. ลักษณะของบริการ SKL คอนเน็กต์ (SKL Connect)</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >3.1 บริการ SKL คอนเน็กต์ (SKL Connect)
            เป็นการให้บริการทางเครือข่ายอินเทอร์เน็ตของบริษัทฯ
            เพื่ออำนวยความสะดวกให้แก่ผู้ขอใช้บริการของบริษัทฯ ในการใช้บริการต่าง
            ๆ เช่น การตรวจสอบข้อมูลสินเชื่อเช่าซื้อ ข้อมูลค่างวด
            การแจ้งเตือนค่างวดที่ถึงกำหนดชำระ
            หรือความเคลื่อนไหวในการชำระเงินค่างวด
            และเพื่อเป็นช่องทางการติดต่อสอบถามระหว่างผู้ขอใช้บริการกับบริษัทฯ
            หรือบริการใดๆ ที่บริษัทฯ ได้จัดให้มีขึ้น และ/หรือ
            จะเปิดให้บริการต่อไปในภายหน้า โดยทำรายการผ่านบัญชี LINE ทั้งนี้
            บริษัทฯ มีสิทธิกำหนดเพิ่มเติม และ/หรือ
            แก้ไขเปลี่ยนแปลงช่องทางหรือเครือข่ายที่ผู้ขอใช้บริการจะสามารถใช้บริการได้ตามที่บริษัทฯ
            เห็นสมควร</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >3.2 บริการชำระเงินออนไลน์ (e-Payment) เป็นระบบที่บริษัทฯ
            พัฒนาเพื่อให้บริการชำระค่างวด และค่าใช้จ่ายอื่นๆ ของบริษัทฯ
            บนการธนาคารบนอินเทอร์เน็ต (Internet Banking) หรือ
            แอปพลิเคชันธนาคารที่เข้าร่วมบริการ (Banking Application)
            ผ่านช่องทางชำระเงินบนบริการ SKL คอนเน็กต์ (SKL Connect)
            โดยผู้ขอใช้บริการรับทราบและยอมรับว่าบริการ SKL คอนเน็กต์ (SKL
            Connect) เป็นเพียงช่องทางเชื่อมต่อไปการธนาคารบนอินเทอร์เน็ต
            (Internet Banking) หรือ แอปพลิเคชันธนาคารที่เข้าร่วมบริการ (Banking
            Application) และ/หรือ ผู้ให้บริการรับชำระเงินเท่านั้น การกระทำใด ๆ
            เพื่อให้มีการหักเงินหรือเรียกเก็บเงินจากบัญชีเงินฝากธนาคารหรือบัญชีใด
            ๆ เป็นข้อตกลงระหว่างเจ้าของบัญชีเงินฝากธนาคารกับธนาคารนั้น ๆ เอง
            กรณีที่การทำรายการชำระเงินผิดพลาด
            ผู้ขอใช้บริการต้องติดต่อธนาคารที่เลือกใช้บริการโดยตรง
            โดยมีรายละเอียดตามเงื่อนไขและข้อกำหนดของบริการชำระเงินออนไลน์
            (e-Payment) ดังนี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(1)
            ผู้ขอใช้บริการรับทราบและยอมรับว่าการชำระเงินผ่านบริการชำระเงินออนไลน์
            (e-Payment) ไม่ใช่การชำระเงินบนระบบของบริษัทฯ โดยตรง
            และการชำระเงินจะสมบูรณ์ต่อเมื่อบริษัทฯ
            ได้รับชำระเงินจากธนาคารหรือผู้ให้บริการรับชำระเงินที่เลือกใช้เรียบร้อยแล้ว
            โดยบริษัทฯ จะจัดส่งใบเสร็จรับเงินและใบกำกับภาษีให้ผู้ใช้บริการ ภายใน
            14 วันทำการหลังจากวันที่บริษัทฯ ได้รับชำระเงินดังกล่าว</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >(2) ผู้ขอใช้บริการรับทราบและยอมรับว่า
            หากผู้ขอใช้บริการได้กดใช้บริการที่ลิงก์หรือเชื่อมโยงท่านไปยังเว็บไซต์หรือแอปพลิเคชันอื่น
            แม้จะผ่านช่องทางใน SKL คอนเน็กต์ (SKL Connect) ของบริษัทฯ ก็ตาม
            ผู้ขอใช้บริการจะต้องศึกษาและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการที่ปรากฏในเว็บไซต์หรือแอปพลิเคชันนั้นๆ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >3.3 บริการ SKL แฟมิลี่คลับ คือ โปรแกรมคำนวณคะแนนสะสม SKL Point
            จากยอดที่ผู้ขอใช้บริการชำระค่างวด
            ตามที่ระบุไว้ในสัญญาเช่าซื้อที่ทำไว้กับบริษัทฯ
            จากทุกช่องทางที่รับชำระเงินที่ทางบริษัทฯ กำหนด
            ซึ่งจัดทำขึ้นเพื่อมอบสิทธิประโยชน์ให้แก่ผู้ขอใช้บริการ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >ทั้งนี้
            ผู้ขอใช้บริการจะต้องศึกษาและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการ
            SKL แฟมิลี่คลับ เพื่อแลกใช้คะแนนสะสม SKL Point
            ตามที่ปรากฏในเว็บไซต์หรือแอปพลิเคชันที่ลิงก์หรือเชื่อมโยงผู้ขอใช้บริการไปยังเว็บไซต์หรือแอปพลิเคชันนั้นๆ
          </span>
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >4.
                ความปลอดภัยและการเก็บรักษาข้อมูลส่วนบุคคลในการใช้บริการ</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >4.1
            ผู้ขอใช้บริการต้องเก็บรักษาข้อมูลส่วนบุคคลและรหัสผ่านแบบใช้ครั้งเดียว
            (OTP)
            สำหรับการลงทะเบียนและการยกเลิกการลงทะเบียนใช้บริการไว้เป็นความลับ
            และไม่เปิดเผย หรือกระทำการใด ๆ ที่อาจทำให้ผู้อื่นทราบได้
            ตลอดจนต้องเก็บรักษาเครื่องมือเพื่อการทำรายการไว้เป็นอย่างดีในที่ปลอดภัย
            และต้องไม่ทำให้เครื่องมือเพื่อทำรายการดังกล่าวตกอยู่ภายใต้การครอบครองของบุคคลอื่น
            เครื่องมือเพื่อการทำรายการของผู้ขอใช้บริการสูญหาย หรือถูกโจรกรรม
            ผู้ขอใช้บริการต้องแจ้ง และ/หรือ ขอระงับ หรืออายัดการใช้บริการ SKL
            คอนเน็กต์ (SKL Connect) ดังกล่าวทันที ทั้งนี้
            ผู้ขอใช้บริการยอมรับว่าการใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            ก่อนที่บริษัทฯ จะได้ทำการระงับการใช้บริการดังกล่าวนั้น
            ให้มีผลผูกพันผู้ขอใช้บริการ
            และผู้ขอใช้บริการตกลงรับผิดชอบทุกประการ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >4.2 ผู้ขอใช้บริการตกลงและยอมรับความเสี่ยง และ/หรือ ความเสียหายใดๆ
            ที่เกิดขึ้นหรืออาจเกิดจากการใช้บริการผ่านเครื่องมือเพื่อการทำรายการรวมถึงความล่าช้า
            ความผิดพลาด การสูญหาย การถูกปลอม และ/หรือ
            การถูกแก้ไขเปลี่ยนแปลงรายการหรือข้อมูลหรือเอกสาร
            ซึ่งอาจเกิดขึ้นในระหว่างเวลาหรือภายหลังเวลาที่รายการหรือข้อมูลหรือเอกสารได้ส่งออกจากผู้ขอใช้บริการ
            หรือไปถึงผู้ขอใช้บริการ โดยไม่ถือว่าเป็นความผิดของบริษัทฯ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >5. ความสมบูรณ์ของการใช้บริการและผลผูกพันผู้ขอใช้บริการ</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.1 ผู้ขอใช้บริการสามารถใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            ได้ตามรายละเอียดประเภทบริการที่บริษัทฯ อนุมัติ
            โดยผู้ขอใช้บริการตกลงผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการที่บริษัทฯ
            กำหนด</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.2 การใช้บริการ SKL คอนเน็กต์ (SKL Connect) ในแต่ละครั้ง
            ผู้ขอใช้บริการต้องทำรายการผ่านระบบปฏิบัติการ LINE Application
            ที่ติดตั้งบนเครื่องมือเพื่อการทำรายการ โดยต้องปฏิบัติตามขั้นตอน
            วิธีการ และเงื่อนไขการใช้บริการที่บริษัทฯ
            กำหนดได้อย่างถูกต้องครบถ้วน</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.3 ผู้ขอใช้บริการสามารถทำรายการเพื่อใช้บริการ SKL คอนเน็กต์ (SKL
            Connect) ตลอด 24 ชั่วโมง โดยไม่จำกัดจำนวนครั้งต่อวัน
            เว้นแต่บริษัทฯจะกำหนดเป็นอย่างอื่นในภายหน้า ตามที่เห็นสมควร</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.4 ผู้ขอใช้บริการสามารถใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            เพื่อสร้างบาร์โคดสำหรับการชำระเงินค่างวดให้แก่บริษัทฯ
            ผ่านเครื่องบริการเงินด่วน (ATM) ของธนาคารที่ร่วมให้บริการ
            หรือช่องทางที่อื่นๆ ที่บริษัทฯ กำหนด หรือกดลิงค์ตามที่บริษัทฯ
            กำหนดเพื่อไปยังแอปพลิเคชันของธนาคารที่ร่วมให้บริการ
            และดำเนินการชำระค่างวดให้แก่บริษัทฯ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.5 ข้อมูลที่ผู้ขอใช้บริการได้จากบริการ SKL คอนเน็กต์ (SKL Connect)
            เช่น ข้อมูลเกี่ยวกับสินเชื่อเช่าซื้อ ข้อมูลค่างวด หรือข้อมูลอื่นใด
            เป็นเพียงข้อมูลเบื้องต้นเท่านั้น</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.6 ผู้ขอใช้บริการตกลงยอมรับว่าวิธีการดำเนินการใด ๆ โดยผ่านบริการ
            SKL Connect ตามข้อกำหนดและเงื่อนไขการใช้บริการนี้
            เป็นวิธีที่น่าเชื่อถือยอมรับได้ระหว่างผู้ขอใช้บริการกับบริษัทฯ
            รวมทั้งรับรองว่าเอกสาร และ/หรือ ข้อมูลใดๆ ที่บริษัทฯ
            ได้รับจากผู้ขอใช้บริการด้วยวิธีดังกล่าวนั้นเป็นเอกสาร และ/หรือ
            ข้อมูลที่เชื่อถือได้และถูกต้อง
            เสมือนเอกสารที่เป็นลายลักษณ์อักษรทุกประการ
            และใช้บังคับระหว่างผู้ขอใช้บริการ บริษัทฯ และผู้เกี่ยวข้อง</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.7 ผู้ขอใช้บริการตกลงและยอมรับว่าการกระทำใดผ่านบริการ SKL
            คอนเน็กต์ (SKL Connect) เช่น บริการตรวจสอบข้อมูลสินเชื่อเช่าซื้อ
            หรือบริการประเภทใด ๆ ก็ตาม หากได้กระทำโดยผ่านบัญชี LINE และหรือบัญชี
            SKL คอนเน็กต์ (SKL Connect)
            ตามข้อกำหนดและเงื่อนไขของการใช้บริการนี้แล้ว และ/หรือ
            หากได้กระทำไปโดยมีการยืนยันด้วย OTP (One Time Password)
            หรือรหัสอื่นใดที่ผู้ขอใช้บริการได้รับผ่านโทรศัพท์มือถือตามหมายเลขโทรศัพท์มือถือที่ผู้ขอใช้บริการได้แจ้งไว้กับบริษัทฯ
            ณ ตอนทำสัญญาเช่าซื้อ และ/หรือ
            มีการแก้ไขข้อมูลซึ่งถูกบันทึกไว้ในระบบของบริษัทฯ
            นั้นให้ถือว่าถูกต้องสมบูรณ์และพันผูกขอใช้บริการทุกประการ
            และถือเป็นการลงลายมือชื่ออิเล็กทรอนิกส์ตามกฎหมาย
            โดยผู้ขอใช้บริการไม่ต้องลงลายมือชื่อในเอกสารใดๆทั้งสิ้น
            และผู้ขอใช้บริการตกลงรับผิดชอบการกระทำดังกล่าวเสมือนว่าผู้ขอใช้บริการเป็นผู้กระทำด้วยตนเอง</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.8 บริษัทฯ มีสิทธิ์ให้ผู้ขอใช้บริการจัดทำเอกสารตามรูปแบบ
            หรือส่งมอบเอกสารให้กับบริษัทฯ ภายในระยะเวลาที่บริษัทฯ กำหนด</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.9 ผู้ขอใช้บริการจะเปลี่ยนแปลง หรือระงับ
            หรือยกเลิกรายการการใช้บริการภายหลังจากที่ได้จัดส่งรายการนั้นให้แก่บริษัทฯ
            ผ่านบริการ SKL คอนเน็กต์ (SKL Connect) แล้วไม่ได้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.10
            ผู้ขอใช้บริการตกลงว่าในกรณีที่บัญชีข้อมูลสินเชื่อของผู้ขอใช้บริการได้เปลี่ยนแปลงไปไม่ว่าด้วยเหตุใดก็ตาม
            ให้ข้อกำหนดและเงื่อนไขนี้ยังคงมีผลบังคับสำหรับบัญชีข้อมูลสินเชื่อของผู้ขอใช้บริการที่ได้เปลี่ยนแปลงนั้นด้วยทุกประการ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.11 เมื่อผู้ขอใช้บริการไม่ได้ใช้งาน หรือมีการโอน
            หรือมีการเปลี่ยนแปลงผู้ครอบครองการใช้งานโทรศัพท์สำหรับยืนยันการทำธุรกรรม
            ผู้ขอใช้บริการตกลงจะแจ้งให้บริษัทฯ
            ทราบตามวิธีการและช่องทางที่บริษัทฯ กำหนดทันที
            มิฉะนั้นผู้ขอใช้บริการตกลงยินยอมรับผิดชอบทั้งสิ้น</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.12
            ผู้ขอใช้บริการตกลงยอมรับผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการนี้
            รวมถึงระเบียบคู่มือและเอกสารอธิบายวิธีการใช้บริการตามที่บริษัทฯ
            กำหนด
            ซึ่งผู้ขอใช้บริการได้กดยืนยันและยอมรับผูกพันในขั้นตอนการสมัครขอใช้บริการ
            และถือว่าผู้ขอใช้บริการได้รับไว้แล้วในวันที่ทำรายการสมัครขอใช้บริการ
            รวมทั้งตามที่บริษัทฯ จะได้กำหนดเพิ่มเติม แก้ไข
            หรือเปลี่ยนแปลงในภายหน้า ซึ่งบริษัทฯ
            จะแจ้งให้ทราบล่วงหน้าไม่น้อยกว่า 30 วัน ผ่านช่องทาง SKL คอนเน็กต์
            (SKL Connect) นี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >5.13 ผู้ขอใช้บริการตกลงและยินยอมให้บริษัทฯ มีสิทธิแก้ไข เปลี่ยนแปลง
            เปลี่ยนแปลง เพิ่มเติมข้อกำหนดและเงื่อนไขการใช้บริการฉบับนี้
            หรือยกเลิกการให้บริการ SKL คอนเน็กต์ (SKL Connect)
            ไม่ว่าทั้งหมดหรือบางส่วน รวมทั้งอัตราค่าธรรมเนียม และ/หรือค่าใช้จ่าย
            และ/หรือค่าบริการ เมื่อใดก็ได้ตามที่บริษัทฯ เห็นสมควร โดยบริษัทฯ
            จะประกาศหรือแจ้งล่วงหน้าไม่น้อยกว่า 30 วัน ผ่านช่องทาง SKL คอนเน็กต์
            (SKL Connect) นี้</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >6. การติดต่อและการส่งคําบอกกล่าว</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >เอกสาร หรือหนังสือ หรือข้อมูลใด ๆ ที่บริษัทฯ
            ส่งไปยังผู้ขอใช้บริการตามที่อยู่ล่าสุด และ/หรือ E-mail Address
            ล่าสุด ตามที่ผู้ขอใช้บริการแจ้งไว้ในการใช้บริการบัญชีเพื่อใช้บริการ
            หรือผ่านบัญชี LINE ของผู้ขอใช้บริการ ให้ถือว่าได้ส่งโดยชอบ
            และผู้ขอใช้บริการได้ทราบข้อความในเอกสาร หรือหนังสือ หรือข้อมูลนั้นๆ
            แล้ว และหากมีการเปลี่ยนแปลงที่อยู่ หรือ E-mail Address ดังกล่าว
            ผู้ขอใช้บริการจะต้องแจ้งให้บริษัทฯ ทราบเป็นลายลักษณ์อักษรทันที
            โดยแจ้งที่สำนักงานสาขาของบริษัทฯ หรือช่องทางอื่นใดตามที่บริษัทฯ
            กําหนด</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >7. การดำเนินการกรณีพบข้อผิดพลาด</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >ในกรณีที่ผู้ขอใช้บริการพบว่ามีข้อผิดพลาดหรือมีความผิดปกติใด ๆ
            ในการใช้บริการ SKL คอนเน็กต์ (SKL Connect)
            ผู้ขอใช้บริการจะต้องแจ้งให้บริษัทฯ ทราบโดยเร็วที่
            ศูนย์ลูกค้าสัมพันธ์สยามคูโบต้า ลีสซิ่ง โทร.1317 หรือ 0 2833 3555
            โดยแจ้งข้อมูลที่ทำรายการ วันและเวลาที่ทำรายการ
            รวมทั้งข้อมูลอื่นใดที่เกี่ยวกับข้อผิดพลาดหรือความผิดปกติดังกล่าว</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-right:8.5pt;text-align:left;text-indent:10.25pt;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >8. ความรับผิดชอบและข้อยกเว้นความรับผิดของบริษัทฯ</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >8.1 การกระทำใด ๆ ที่บริษัทฯ ได้กระทำไปตามข้อกำหนดและเงื่อนไขนี้
            และ/หรือ ตามข้อมูลที่ผู้ขอใช้บริการได้แจ้งต่อบริษัทฯ และ/หรือ
            ตามคำร้องขอผู้ขอใช้บริการ และ/หรือ
            ตามข้อกำหนดและเงื่อนไขการใช้บริการใด ๆ ของบริษัทฯ
            ไม่ว่าที่มีอยู่แล้วในขณะนี้หรือที่จะมีขึ้นในภายหน้าให้มีผลผูกพันผู้ขอใช้บริการทุกประการ
            และบริษัทฯ ไม่ต้องรับผิดชอบในความเสียหายใด ๆ
            อันเกิดแก้ผู้ขอใช้บริการและ/หรือ บุคคลใด ๆ
            ไม่ว่าจะโดยประการใดทั้งสิ้น</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >8.2 ผู้ขอใช้บริการตกลงและยอมรับว่า บริษัทฯ
            ไม่ต้องรับผิดชอบในความผิดพลาด ความบกพร่อง ความล่าช้า
            หรือเหตุขัดข้องในการใช้บริการ
            อันเนื่องมาจากการที่ผู้ขอใช้บริการใช้เครื่องมือเพื่อการทำรายการไม่เป็นไปตามที่บริษัทฯ
            กำหนด หรือเกิดจากเหตุสุดวิสัย
            หรือเหตุอื่นใดที่อยู่นอกเหนือการควบคุมของบริษัทฯ</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >8.3 ในกรณีที่มีความผิดพลาด บกพร่อง ล่าช้า
            หรือมีความเสียหายเกิดขึ้นจากเครื่องมือเพื่อการทำรายการของผู้ขอใช้บริการหรือกระบวนการหรือวิธีการจัดส่งข้อความหรือข้อมูลหรือรายการการใช้บริการผ่าน
            LINE Application หรือระบบเครือข่ายโทรศัพท์มือถือ
            หรือระบบอินเทอร์เน็ต
            หรือจากการกระทำของผู้ให้บริการเครือข่ายโทรศัพท์มือถือหรือผู้ให้บริการระบบอินเทอร์เน็ต
            หรือผู้ให้บริการ LINE Application
            ถือเป็นความรับผิดชอบของผู้ขอใช้บริการ และ/หรือผู้ให้บริการดังกล่าว
            บริษัทฯ ไม่ต้องรับผิดชอบในความเสียหายใดๆ
            อันเกิดแก้ผู้ขอใช้บริการและ/หรือ บุคคลใด ๆ ไม่ว่าจะในกรณีใดๆ
            ทั้งสิ้น</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >9. การระงับ และ/หรือ การยกเลิกการให้บริการ</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >9.1 บริษัทฯ มีสิทธิ์ที่จะไม่ให้บริการ หรือระงับ
            หรือยกเลิกการให้บริการนี้ ประเภทใดประเภทหนึ่ง
            หรือทั้งหมดเมื่อใดก็ได้
            โดยแจ้งให้ผู้ขอใช้บริการทราบล่วงหน้าไม่น้อยกว่า 30 วัน
            เว้นแต่ในกรณีที่บริษัทฯ ไม่สามารถแจ้งให้ทราบล่วงหน้าได้ บริษัทฯ
            จะแจ้งให้ผู้ขอใช้บริการทราบโดยเร็ว ในกรณีดังต่อไปนี้ บริษัทฯ
            มีสิทธิ์ระงับหรือยกเลิกการใช้บริการนี้ทันทีที่ บริษัทฯ
            พบเหตุดังกล่าวได้บัญชีข้อมูลสินเชื่อของผู้ขอใช้บริการถูกปิดหรือถูกยกเลิกไม่ว่าโดยผู้ขอใช้บริการหรือโดยบริษัท
            เนื่องจากบัญชีข้อมูลสินเชื่อจะถูกปรับสถานะเป็นการปิดบัญชีตามเงื่อนไขที่บริษัทฯ
            กำหนดหรือโดยเหตุอื่นใด บริษัทฯ สงสัยหรือเห็นว่า
            ผู้ขอใช้บริการมิใช่เจ้าของหรือมีผู้มีสิทธิ์ใช้หมายเลขโทรศัพท์สำหรับยืนยันการทำรายการ
            หรือมีการแก้ไข หรือมีการใช้บริการนี้หรือใช้บัญชีของผู้ใช้บริการ
            เพื่อในกิจการหรืออาจเป็นการขัดต่อกฎหมายหรือความสงบเรียบร้อยหรือมีลักษณะเป็นธุรกรรมที่อาจผิดกฎหมาย
            หรือมีพฤติกรรมอันน่าเชื่อได้ว่าการใช้บริการ หรือการทำรายการนี้
            โดยทุจริตหรือโดยไม่ชอบไม่ว่าด้วยประการใด ๆ บริษัทฯ
            ต้องปฏิบัติตามกฎหมาย ระเบียบ ข้อบังคับ หรือคำสั่งศาล
            หรือผู้มีอำนาจตามกฎหมาย</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >9.2 ผู้ขอใช้บริการมีสิทธิ์ยกเลิกการใช้บริการนี้ไม่ว่าประเภทใด
            ประเภทหนึ่ง หรือทุกประเภท เมื่อใดก็ได้ โดยทำรายการผ่านบัญชี SKL
            คอนเน็กต์ (SKL Connect)
            และดำเนินการตามขั้นตอนและวิธีที่แสดงบนหน้าจอในขณะทำรายการนั้น
            หรือแจ้งผ่านศูนย์ลูกค้าสัมพันธ์สยามคูโบต้า ลีสซิ่ง โทร. 1317 หรือ 0
            2833 3555 หรือแจ้งผ่านช่องทางอื่นใดตามที่บริษัทฯ
            จะกำหนดเพิ่มเติม</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >10. นโยบายคุ้มครองข้อมูลส่วนบุคคล
              </span></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-left:31.55pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >ผู้ใช้บริการสามารถศึกษารายละเอียดการประมวลผลข้อมูลส่วนบุคคลของบริษัทฯ
            ได้ที่
            <a href="https://www.skl.co.th/privacy_policy"
              >https://www.skl.co.th/privacy_policy</a
            ></span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >11. การติดต่อบริษัทฯ
              </span></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-left:31.55pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >บริษัท สยามคูโบต้า ลีสซิ่ง จำกัด (สำนักงานใหญ่) 101/19-24 หมู่ 20
            ซอยนวนคร ตำบลคลองหนึ่ง อำเภอคลองหลวง จังหวัดปทุมธานี 12120
            ศูนย์ลูกค้าสัมพันธ์สยามคูโบต้า ลีสซิ่ง โทร.1317 หรือ 0 2833
            3555</span
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-right:8.5pt;text-align:left;text-indent:10.25pt;'
        >
          <strong
            ><u
              ><span style='font-family:"Kanit", sans-serif;'
                >12. กฎหมายที่ใช้บังคับ</span
              ></u
            ></strong
          >
        </p>

        <p
          style='margin:0in;margin-bottom:.0001pt;font-family:"Kanit", sans-serif;margin-top:0in;margin-right:8.5pt;margin-left:8.55pt;text-align:left;text-indent:21.25pt;'
        >
          <span style='font-family:"Kanit", sans-serif;'
            >ข้อกำหนดและเงื่อนไขการใช้บริการนี้ให้อยู่ภายใต้บังคับและตีความตามกฎหมายแห่งราชอาณาจักรไทย</span
          >
        </p>
      </template>
      <template v-else><p v-html="content"></p></template>
    </template>
    <template v-slot:modal-footer>
      <!-- <div
        class="d-flex flex-column align-items-center policy-bottom-area"
        ref="policyBtnArea"
      > -->
      <div id="policy-submit-area">
        <div
          id="checkbox-check"
          class="d-flex flex-row checkbox-area align-items-center"
          @click="clickPolicyCheckBox"
        >
          <div class="checkbox">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
            >
              <g
                id="Rectangle_1784"
                data-name="Rectangle 1784"
                fill="#fff"
                stroke="#707070"
                stroke-width="1"
              >
                <rect width="23" height="23" rx="3" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="22"
                  height="22"
                  rx="2.5"
                  fill="none"
                />
              </g>
              <path
                v-if="policyState.selected"
                id="Path_8841"
                data-name="Path 8841"
                d="M-18087.189-17236.355l5.17,5.17,6.658-11"
                transform="translate(18093 17248)"
                fill="none"
                stroke="#80c141"
                stroke-linecap="round"
                stroke-width="2"
              />
            </svg>
          </div>
          <div class="f-small-gray">
            ยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ
          </div>
        </div>
        <b-button
          class="mt-2 w-100 submit-policy"
          id="submit-policy"
          :disabled="!policyState.selected || buttonLoading"
          variant="submit"
          @click="submitPolicy"
        >
          <b-spinner small v-if="buttonLoading"></b-spinner>
          <span v-else>ยินยอม</span>
        </b-button>
        <b-modal id="comfirmPolicy" centered hide-footer>
          <template v-slot:modal-title>
            ยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ
          </template>
          <div class="d-block text-center">
            คุณได้อ่านและยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ
          </div>
          <b-button
            class="mt-3"
            block
            @click="
              policyState.selected = !policyState.selected;
              policyState.showCheckBox = true;
              $bvModal.hide('comfirmPolicy');
            "
            >ยอมรับ</b-button
          >
          <b-button class="mt-3" block @click="$bvModal.hide('comfirmPolicy')"
            >ยกเลิก</b-button
          >
        </b-modal>
      </div>
      <!-- </div> -->
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      content: "",
      buttonLoading: false,
      policyState: {
        showCheckBox: false,
        selected: false
      }
    };
  },
  mounted() {},
  methods: {
    addScrollListener() {
      setTimeout(() => {
        var el = document.getElementById("modal-policy___BV_modal_body_");
        el.addEventListener("scroll", () => {
          this.policyonScroll();
        });
      }, 0);
    },
    policyonScroll() {
      var element = document.getElementById("modal-policy___BV_modal_body_");
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        this.policyState.showCheckBox = true;
      }
    },
    clickPolicyCheckBox() {
      if (this.policyState.showCheckBox) {
        this.policyState.selected = !this.policyState.selected;
      } else {
        this.$bvModal.show("comfirmPolicy");
      }
    },
    submitPolicy() {
      this.buttonLoading = true;
      this.$emit("Accept");
    }
  }
};
</script>

<style scoped>
#policy {
  /* width: 90%; */
  /* height: calc(100% - 90px); */
  overflow: auto;
  font-size: 14px;
}
#policy-submit-area {
  width: 100%;
}
#policy-submit-area.hidden {
  display: none !important;
}
.policy-bottom-area {
  position: fixed;
  bottom: 0;
  padding: 10px 0;
  height: 100px;
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.44);
}
.checkbox {
  margin-left: 12px;
  margin-right: 12px;
}
.checkbox-area {
  margin-top: 5px;
  margin-bottom: 5px;
}
.w-100.submit-policy {
  width: 100% !important;
}
</style>